.management-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    min-width: calc(100vw - 20vw);
    height: 92vh;
}

.management-wrap-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}

.management-user-select {
    margin-top: 20px;
    width: 190px;
    height: 40px;
    border-radius: 6px;
    border: 1px solid #8c8c8c;
}

.management {
    margin-top: 20px;
    margin-left: 10px;

    display: flex;
    flex-direction: column;

    margin: 30px 20px 10px 20px;
}

.management-row-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.management-header {
    display: flex;
}

.management-header-item {
    border: 1px solid white;

    min-width: 130px;
    min-height: 40px;

    columns: white;
    background-color: #AAAAAA;

    display: flex;
    justify-content: center;
    align-items: center;
}

.management-body {
    display: flex;
}

.management-body-item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    border: 1px solid #E9E9EF;

    width: 130px;
    height: 30px;
}

.management-body-item-button {
    background-color: #49A142;
    border: none;
    border-radius: 4px;
    color: white;
    margin: 5px;
}

.management-body-item-delete-button {
    background-color: rgb(201, 0, 0);
    border: none;
    border-radius: 4px;
    color: white;
    margin: 5px;
}

.management-add-button {
    color: white;
    background-color: #49A142;
    padding: 10px 35px 10px 30px;
    margin: 10px 0px 10px 10px;
    border-radius: 5px;
}

.management-body-item input {
    width: 100px;
    border-radius: 6px;
    border: 1px solid #b1b1b1;
}

.management-body-item select {
    width: 95%;
    height: 85%;
    border-radius: 6px;
    border: 1px solid #8c8c8c;
}

.management-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px;
}