.logo_backgroud {
    display: inline;
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0px;
    top: 0px;
    opacity: 1;
    transition: opacity 0.3s ease;

    background: #49a142;
    box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.25);
}

.logo_img {
    position: absolute;
    width: 70%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.login-wrapper {
    display: inline;
    transform: translate(-50%, -50%);

    position: absolute;
    width: 30%;
    min-width: 300px;
    height: 60%;
    left: 75%;
    top: 50%;
}

.login_p {
    position: relative;

    font-family: 'Poppins';
    font-size: 200%;
    line-height: 48px;

    color: #5A5A5F;
}

.id_label {
    margin-top: 15px;
    position: relative;

    font-family: 'Poppins';
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    letter-spacing: 0.02em;

    color: #5A5A5F;

}

.id_input {
    position: relative;
    box-sizing: border-box;
    margin-top: 5px;
    margin-bottom: 10px;
    width: 100%;
    height: 56px;

    background: #FFFFFF;
    border: 1px solid #B6B6BB;
    border-radius: 4px;
}

.password_lable {
    position: relative;
    height: 21px;

    font-family: 'Poppins';
    font-size: 14px;
    line-height: 21px;

    letter-spacing: 0.02em;

    color: #5A5A5F;
}

.password_input {
    position: relative;
    margin-top: 10px;

    box-sizing: border-box;

    width: 100%;
    height: 56px;

    background: #FFFFFF;
    border: 1px solid #B6B6BB;
    border-radius: 4px;
}

.login_btn {
    position: relative;
    margin-top: 20px;

    color: #FFFFFF;
    font-size: 32px;
    gap: 8px;

    width: 100%;
    height: 56px;

    background: #49a142;
    border-color: #49a142;
    border-radius: 4px;

    opacity: 1;
    transition: 0.2s ease;
}
.login_btn:hover {
    opacity: 0.8;
    transition: 0.2s ease;
}

.login_error {
    color: red;
    position: absolute;
    top: 350px;
}

@media only screen and (max-width : 600px) {
    .logo_backgroud {
        transition: opacity 0.3s ease;
        opacity: 0;
    }

    .login-wrapper {
        display: inline;

        position: absolute;
        width: 60%;
        height: 60%;
        left: 50%;
        top: 50%;
    }
}

.join-wrapper {
    display: ;
    margin: 0 0 0 20px;
}