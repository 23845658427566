.nav-wrapper {
    position: relative;
    width: 100vw;
    height: 8vh;
    min-height: 50px;

    background-color: #EEE;

    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    box-shadow: 0px 4px 4px -4px rgba(0, 0, 0, 0.25);
}


.nav-wrapper-side {
    width: 20vw;
    min-width: 250px;
    height: 8vh;
    min-height: 50px;

    background-color: #EEE;

    display: flex;
    align-items: center;
    z-index: 100;
}

.nav-wrapper-main {
    width: 80vw;
    height: 8vh;
    min-height: 50px;

    background-color: #EEE;

    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}

.sidebar-toggle {
    border: none;
    min-width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;

    background-image: url('/public/icon/menu_FILL0_wght400_GRAD0_opsz48.svg');
    background-size: cover;
    opacity: 0.5;

    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-logo {
    height: 7vh;
    min-height: 50px;
}

.nav-menu {
    height: inherit;
    display: flex;
    align-items: center;
}

.nav-logout {
    border: none;
    margin-right: 30px;
    height: 40px;    
    width: 40px;
    background-image: url('/public/icon/logout_FILL0_wght400_GRAD0_opsz48.svg');
    background-size: cover;
}