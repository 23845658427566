.loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.loading-icon {
    width: 15%;
    animation: spin 2.5s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}