.district-button-wrapper {
    display: flex;
    flex-direction: row;

    height: inherit;
    width: inherit;
    min-width: 100px;
}

.district-button {
    width: auto;
    min-width: 100px;
    min-height: 50px;
    font-size: large;
    height: inherit;
    padding: 0 10px 0 10px;
    border: none;
}

.district-button:hover {
    background-color: rgba(131, 131, 131, 0.5);
}

.district-button-highlight {
    width: auto;
    height: auto;

    min-width: 100px;
    min-height: 50px;

    font-size: large;
    padding: 0 10px 0 10px;
    border: none;
    background-color: rgba(73, 161, 66, 0.5);
}