
.video-view-card {
    height: 500px;
    width: 800px;
    background: lightgrey;
    border-radius: 25px;
    box-shadow: 7px 7px 13px 4px rgba(102, 102, 102, 0.25);
    margin: 20px 20px 0px 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.video-view-header {
    height: 10%;
    width: inherit;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.video-view-body {
    height: 95%;
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.video-monitor {
    width: 100%;
    aspect-ratio: 16/9;
    background-color: blue;
}
.video-scroll-menu {
    width: 25%;
    border-radius: 25px;
    height: inherit;
    overflow: scroll;
    background-color: red;
    align-items: center;
    overflow-x: hidden;
}
.video-scroll-menu-item {
    width: 85%;
    height: 10%;
    background-color: green;
    border-radius: 25px;
    margin: 10px;
}
.video-js {
    width: 100%;
    height: 100%;
    background: transparent;
}