.device-card {
    /* width: 280px; */
    height: 100px;
    background: lightgrey;
    border-radius: 25px;
    box-shadow: 7px 7px 13px 4px rgba(102, 102, 102, 0.25);
    margin: 20px 20px 0px 20px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.device-card-wrapper-vertical {
    display: flex;
    flex-direction: column;
}

.device-card-data {
    display: flex;
    align-items: center;
    min-width: 100px;
    height: 40px;
    justify-content: center;
    padding: 0 10px 0 10px;
}

.device-card-data-narrow {
    display: flex;
    align-items: center;
    min-width: 50px;
    height: 40px;
    justify-content: center;

}

.monitoring-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.toggle-switch {
    appearance: none;
    position: relative;
    border: max(2px, 0.1em) solid gray;
    border-radius: 1.25em;
    width: 2.25em;
    height: 1.25em;
}

.toggle-switch::before {
    content: "";
    position: absolute;
    left: 0px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    transform: scale(0.8);
    background-color: gray;
    transition: left 250ms linear;
}

:checked {
    background-color: gray;
    border-color: gray;
}

.toggle-switch:checked::before {
    left: 1em;
    background-color: #48a142;
}

.toggle-switch:enabled:hover {
    box-shadow: 0 0 0 max(4px, 0.2em) #48a1425a;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.input-number {
    width: 2em;
    height: 1.8em;
    text-align: center;
    border: none;
    border-radius: 0.25em;
    font-size: 1.1em;
}

.btn-arrow-up {
    background-image: url('/public/icon/keyboard_arrow_up_FILL0_wght400_GRAD0_opsz48.svg');
    background-size: cover;
    margin: 0.2em;
    width: 2em;
    height: 2em;
    background-color: transparent;
    border: none;
}

.btn-arrow-down {
    background-image: url('/public/icon/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz48.svg');
    background-size: cover;
    margin: 0.2em;
    width: 2em;
    height: 2em;
    background-color: transparent;
    border: none;

}

/* 버튼 클릭시 */
.btn-arrow-up:active {
    background-color: white;
}
.btn-arrow-down:active {
    background-color: white;
}

.img-ac {
    width: 2em;
    height: 2em;
    background-size: cover;
    background-image: url('/public/icon/ac_unit_FILL0_wght400_GRAD0_opsz48.svg');
}