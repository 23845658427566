.site-select-box {
    margin: 0 10px 0 0;
    width: 100%;
    height: 70%;

    border: 1px solid #909090;
    border-radius: 8px;

    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;

    font-size: 175%;
}