.side-wrapper {
    position: relative;
    width: 20vw;
    min-width: 250px;
    height: 92vh;
    min-height: 50px;

    background-color: #EEE;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
}

.flex {
    display: flex;
    flex-direction: row;
}

.sidebar-div {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    height: 70px;
    font: 400 1.5em/1.5em 'Roboto', sans-serif;
}

.sidebar-div:hover {
    background-color: rgba(131, 131, 131, 0.5);
}

.sidebar-div-highlight {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    height: 70px;
    background-color: rgba(73, 161, 66, 0.5);
    font: 400 1.5em/1.5em 'Roboto', sans-serif;
}

.sidebar-div img {
    margin-left: 25px;
    margin-right: 15px;
    opacity: 0.5;
}

.sidebar-div-highlight img {
    margin-left: 25px;
    margin-right: 15px;
    opacity: 0.5;
}